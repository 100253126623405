<script setup lang="ts">
import type { ProductsData, ProductsResponseSchema } from '@laam/cms-shared';
import ProductCard from './ProductCard.vue';
import { parseAbsoluteToLocal } from '@internationalized/date';
import { useState } from 'nuxt/app';

import {
	computed,
	inject,
	onBeforeMount,
	onBeforeUnmount,
	onServerPrefetch,
	ref,
	type Ref,
} from 'vue';

interface Props {
	metadata: ProductsData;
	data: ProductsResponseSchema;
	sectionIndex: number;
}

const props = defineProps<Props>();

const showCarousel = useState('showCarousel', () => true);

const deviceType = inject('deviceType') as Ref<string | undefined>;

const productsCarouselRef = ref();

const countDown = useState<{
	days: number;
	hours: number;
	minutes: number;
	seconds: number;
}>('countDown', () => ({
	days: 0,
	hours: 0,
	minutes: 0,
	seconds: 0,
}));

const interval = ref(1000);

const timer = () => {
	if (!props.metadata.timer) return;

	const now = new Date().getTime();
	const endTime = parseAbsoluteToLocal(props.metadata.timer.endDateTime);

	const timeDiff = endTime.toDate().getTime() - now;

	if (timeDiff > 0) {
		countDown.value.days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
		countDown.value.hours = Math.floor(
			(timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
		);
		countDown.value.minutes = Math.floor(
			(timeDiff % (1000 * 60 * 60)) / (1000 * 60),
		);
		countDown.value.seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);
	} else {
		showCarousel.value = false;
	}
};

onBeforeUnmount(() => {
	clearInterval(interval.value);
});
const isTimerRunning = computed(() => {
	if (!props.metadata.timer) return false;

	if (
		parseAbsoluteToLocal(props.metadata.timer.startDateTime)
			.toDate()
			.getTime() < new Date().getTime() &&
		new Date().getTime() <
			parseAbsoluteToLocal(props.metadata.timer.endDateTime).toDate().getTime()
	)
		return true;
	else return false;
});

onServerPrefetch(() => {
	if (
		props.metadata.timer &&
		props.metadata.timer.enabled &&
		props.metadata.timer.startDateTime !== '' &&
		props.metadata.timer.endDateTime !== ''
	) {
		if (isTimerRunning.value) {
			timer();
			showCarousel.value = true;
		} else showCarousel.value = false;
	} else {
		showCarousel.value = true;
	}
});

onBeforeMount(() => {
	if (
		props.metadata.timer &&
		props.metadata.timer.enabled &&
		props.metadata.timer.startDateTime !== '' &&
		props.metadata.timer.endDateTime !== ''
	) {
		if (isTimerRunning.value) {
			setInterval(timer, interval.value);
			showCarousel.value = true;
		} else showCarousel.value = false;
	} else {
		showCarousel.value = true;
	}
});
</script>

<template>
	<div
		v-if="showCarousel"
		class="gap-3xl lg:px-0 px-3xl flex flex-col items-center"
	>
		<div
			class="flex gap-3xl w-full"
			:class="{
				'flex-col': metadata.alignment === 'center',
				'flex-row justify-between': metadata.alignment === 'left',
			}"
		>
			<div
				class="gap-sm flex flex-col grow w-full"
				:class="{
					'items-start': metadata.alignment === 'left',
					'items-center': metadata.alignment === 'center',
				}"
			>
				<h2 class="text-lg lg:display-xs font-semibold text-gray-900">
					{{ metadata.title }}
				</h2>
			</div>

			<div v-if="isTimerRunning" class="countdown-wrapper">
				<h5 class="mr-[1px] text-sm font-medium text-gray-900">Ending in</h5>
				<div class="day digit-span">{{ countDown.days }}</div>
				:
				<div class="hours digit-span">{{ countDown.hours }}</div>
				:
				<div class="minutes digit-span">{{ countDown.minutes }}</div>
				:
				<div class="seconds digit-span">{{ countDown.seconds }}</div>
			</div>
		</div>

		<Carousel
			ref="productsCarouselRef"
			class="cms--carousel-products w-full"
			:items-to-show="deviceType === 'mobile' ? 2 : 4"
			snap-align="start"
		>
			<Slide
				v-for="(field, horizontalIndex) in data.products"
				:key="field"
				:index="horizontalIndex"
			>
				<div class="pr-3xl lg:pr-7xl w-full overflow-hidden">
					<slot :horizontal-index="horizontalIndex" :product="field">
						<ProductCard :index="horizontalIndex" :data="field" />
					</slot>
				</div>
			</Slide>

			<template #addons>
				<Navigation v-if="deviceType !== 'mobile'" />
			</template>
		</Carousel>
	</div>
</template>

<style>
.cms--carousel-products .carousel__prev {
	@apply bg-white rounded-full top-[40%] p-md mx-3xl lg:p-lg lg:mx-5xl;
}

.cms--carousel-products .carousel__next {
	@apply bg-white rounded-full top-[40%] p-md mx-3xl lg:p-lg lg:ml-5xl lg:mr-9xl;
}

.cms--carousel-products .cms--carousel-products__item {
	@apply relative rounded-xs text-xl text-white bg-white w-full;
}

.cms--carousel-products .carousel__slide {
	@apply p-none  items-start;
}

.cms--carousel-products .carousel__pagination {
	@apply absolute mx-auto left-[0px] right-[0px] gap-sm w-fit bottom-3xl;
}

.cms--carousel-products .carousel__pagination-item {
	@apply box-border;
}

.cms--carousel-products .carousel__pagination-button {
	@apply p-none;
}

.cms--carousel-products .carousel__pagination-button::after {
	@apply bg-white rounded-2xl w-10xl h-xs opacity-40;
}

.cms--carousel-products .carousel__pagination-button--active::after {
	@apply bg-white opacity-100;
}

.countdown-wrapper {
	@apply flex items-center gap-xxs text-sm font-semibold text-[#ea0101]  lg:gap-xl lg:text-xl;
}

.countdown-wrapper h5 {
	@apply whitespace-nowrap font-normal text-gray-900;
}

.countdown-wrapper .digit-span {
	@apply flex w-[28px] min-w-[28px] items-center justify-center bg-[#f6f6f6] px-xxs py-xs  text-sm lg:w-[40px] lg:min-w-[40px] lg:px-sm lg:text-xl;
}
</style>
